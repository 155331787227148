<template>
  <div>
    <h1
      class="text-xl text-grey-light font-medium font-headers mb-3 font-bold text-grey-dark-3"
      data-testid="title"
    >
      Admins
    </h1>
    <div v-if="sellerAdminsCount < 1">
      <EmptyState
        :callback="inviteSeller"
        title="My Platform Admins"
        description="No admins added yet."
        btnText="Add User"
      />
    </div>
    <div v-else>
      <SellersTable />
    </div>
  </div>
</template>

<script>
import SellersTable from "../components/SellersTable/index";
import EmptyState from "../components/AdminsTable/EmptyState";
import { mapState, mapActions } from "vuex";

export default {
  name: "MyPlatform",
  components: { SellersTable, EmptyState },
  computed: {
    ...mapState({
      sellerAdminsCount: (state) => state.companies.sellerAdminsCount,
    }),
  },
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
      getSellerAdmins: "companies/getSellerAdmins",
    }),
    inviteSeller() {
      this.openModal({ modal: "addSellersModal" });
    },
  },
  async mounted() {
    await this.getSellerAdmins({});
  },
};
</script>

<style></style>
