<template>
  <div class="bg-white shadow px-2 pt-2 pb-2 rounded">
    <p class="font-barlow text-mdl font-bold text-grey-light mt-1 pb-1">
      {{ title }}
    </p>
    <div class="flex flex-row px-3 justify-between">
      <p class=" font-normal mt-1 pb-2">
        {{ description }}
      </p>
      <div>
        <div>
          <Button
            :text="btnText"
            type="primary"
            :iconLeft="add"
            size="medium"
            class="invite-admin"
            @click.native="callback"
            data-testid="add-button"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import add from "@/assets/img/icons/add.svg";

export default {
  name: "EmptyState",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    btnText: {
      type: String,
      default: "",
    },
    callback: {
      type: Function,
      default: () => null,
    },
  },
  data: () => ({ add }),
};
</script>

<style lang="scss" scoped>
.invite-admin {
  line-height: 1;
}
</style>
