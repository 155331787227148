<template>
  <div class="bg-white shadow px-2 py-3 rounded">
    <p class="font-barlow text-mdl font-bold text-grey-light mb-3">
      My Platform Admins
    </p>
    <div class="px-2">
      <div class="flex flex-row items-end justify-between">
        <div>
          <InputField
            placeholder="Search by name"
            label="Filter by"
            :onChange="setKeyword"
            leftIcon
            class="mb-2 w-inputField"
            data-testid="search-field"
          />
        </div>
        <div class="flex flex-row space-x-2">
          <div class="mb-2">
            <Button
              text="Add Admin"
              type="primary"
              size="medium"
              :iconLeft="plus"
              @click.native="addSeller"
              data-testid="add-user-button"
            />
          </div>
        </div>
      </div>
      <p class="text-sm-2 text-grey-dark-2 mb-2">
        Showing {{ mappedList.length }} admins out of {{ sellerAdminsCount }}
      </p>
      <div>
        <div class="flex flex-row w-full bg-grey-light-3 py-1 pl-3 rounded">
          <div class="flex flex-grow max-w-2/5">
            <button
              class="flex flex-row items-center focus:outline-none"
              @click="() => sort(0)"
              data-testid="header-1"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
              <img
                src="../../../../assets/img/delta-table/caret.svg"
                class="transition-all"
                :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
              />
            </button>
          </div>
          <div class="flex flex-grow max-w-1/4">
            <button
              class="flex flex-row items-center focus:outline-none"
              @click="sort(1)"
              data-testid="header-2"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">EMAIL</p>
              <img
                src="../../../../assets/img/delta-table/caret.svg"
                class="transition-all"
                :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
              />
            </button>
          </div>
          <div class="flex flex-grow max-w-1/10 ml-auto items-center">
            <p class="text-sm-2 text-grey-light mr-0.4">STATUS</p>
          </div>
          <div class="flex flex-grow max-w-1/10 justify-center mr-1.5 items-center">
            <p class="text-sm-2 text-grey-light mr-0.4">ACTIONS</p>
          </div>
        </div>
        <div>
          <VirtualList
            style="max-height: 360px; overflow-y: auto"
            :data-key="'id'"
            :data-sources="mappedList"
            :data-component="itemComponent"
            @tobottom="triggerFetch"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import plus from "@/assets/img/icons/add.svg";

import { mapActions, mapState } from "vuex";

export default {
  name: "SellersTable",
  components: { VirtualList },
  data: () => ({
    plus,
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1],
    mappedList: [],
    pageNr: 1,
    sorting: null,
  }),
  computed: {
    ...mapState({
      sellers: (state) => state.companies.sellers,
      sellerAdminsCount: (state) => state.companies.sellerAdminsCount,
      fetchSellers: (state) => state.companies.fetchSellers,
    }),
  },
  mounted() {
    this.updateList();
  },
  watch: {
    keyword() {
      this.pageNr = 1;
      this.mappedList = [];
      this.updateList();
    },
    fetchSellers(val) {
      if (val) {
        this.updateList(true);
      }
    },
  },
  methods: {
    ...mapActions({
      getSellerAdmins: "companies/getSellerAdmins",
      assignUsers: "adminTeams/assignUsers",
      openModal: "modals/openModal",
    }),
    addSeller() {
      this.openModal({ modal: "addSellersModal" });
    },
    sort(index) {
      this.sorting = null;
      this.pageNr = 0;
      this.mappedList = [];
      this.resetRotationMatrix(index);
      this.rotationMatrix[index] == 1
        ? this.rotationMatrix[index]++
        : this.rotationMatrix[index]--;
      switch (index) {
        case 0:
          this.sorting = { firstName: this.rotationMatrix[index] };
          break;
        case 1:
          this.sorting = { email: this.rotationMatrix[index] };
          break;
        default:
          break;
      }
      this.triggerFetch();
    },
    resetRotationMatrix(idx) {
      this.rotationMatrix.map((_, index, array) =>
        index == idx ? null : (array[index] = 1)
      );
    },
    setKeyword: debounce(function(value) {
      this.keyword = value;
      this.pageNr = 1;
    }, 500),
    triggerFetch() {
      if (this.pageNr < Math.ceil(this.sellerAdminsCount / 10)) {
        this.pageNr++;
        this.updateList();
      }
    },
    updateList(reset) {
      if (reset) {
        this.mappedList = [];
        this.pageNr = 1;
      }
      const payload = {
        keyword: this.keyword,
        pageNr: this.pageNr,
        sorting: this.sorting,
      };
      this.getSellerAdmins({ ...payload }).then((rsp) => {
        let map = rsp.data.data.map((item, idx) => ({
          ...item,
          isSelected: false,
          index: (this.pageNr - 1) * 10 + idx,
        }));
        this.mappedList = [...this.mappedList, ...map];
      });
    },
  },
};
</script>
