<template>
  <div>
    <div class="flex flex-row w-full py-2 pl-3 items-center">
      <div class="flex flex-grow max-w-2/5">
        <p
          class="flex flex-grow text-md text-grey-dark-1 font-semibold max-w-2/5"
        >
          {{ fullName }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/4">
        <p class="text-md text-grey-dark-2">
          {{ source.email }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/10 ml-auto">
        <p
          class="text-md text-grey-dark-2"
          :class="source.active ? 'text-green' : 'text-red'"
        >
          {{ source.active ? 'Active' : 'Inactive' }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/10 justify-center">
        <Button
          text="Manage"
          type="secondary"
          size="medium"
          @click.native="manage"
        />
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Button from '../../../../components/Button/Button.vue';
export default {
  components: { Button },
  name: 'ListItem',
  props: {
    source: { type: Object, default: () => ({}) },
  },
  computed: {
    fullName() {
      return this.source.firstName + ' ' + this.source.lastName;
    },
  },
  methods: {
    ...mapActions({ setSelectedUser: 'companies/setSelectedUser' }),
    manage() {
      this.setSelectedUser({ user: this.source });
      this.$router.push(`/my-platform/${this.source.id}`);
    },
  },
};
</script>
